html,
body {
    margin: 0;
    font-family: lato;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    * {
        outline: none;
    }
}

body::-webkit-scrollbar,
.mat-select-panel::-webkit-scrollbar,
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    transition: height 0.15s ease-out;
}

body::-webkit-scrollbar-track,
.mat-select-panel::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

body::-webkit-scrollbar-thumb,
.mat-select-panel::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    opacity: 0.1;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}

body:hover::-webkit-scrollbar,
.mat-select-panel:hover::-webkit-scrollbar {
    height: 0.6em;
    transition: height 0.25s ease-in;
}

body:hover::-webkit-scrollbar-thumb,
.mat-select-panel:hover::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}

body::-webkit-scrollbar-thumb:hover,
.mat-select-panel::-webkit-scrollbar-thumb:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.55);
}

footer {
    small {
        font-size: 0.7rem;
        text-transform: uppercase;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.resize .border {
    position: absolute;
    background: red;
}

.resize .border-top,
.resize .border-bottom {
    left: 0;
    right: 0;
    height: 1px;
    cursor: n-resize;
}

.resize .border-left,
.resize .border-right {
    top: 0;
    bottom: 0;
    width: 1px;
    cursor: w-resize;
}

.resize .border-left {
    left: 0;
}

.resize .border-right {
    right: 0;
}

.resize .border-top {
    top: 0;
}

.resize .border-bottom {
    bottom: 0;
}

.resize .border-top-left {
    left: 0;
    top: 0;
    height: 4px;
    width: 4px;
    background: transparent !important;
    cursor: se-resize;
    z-index: 999;
}

.resize .border-top-right {
    right: 0;
    top: 0;
    height: 4px;
    width: 4px;
    background: transparent !important;
    cursor: ne-resize;
    z-index: 999;
}

.resize .border-bottom-left {
    left: 0;
    bottom: 0;
    height: 4px;
    width: 4px;
    background: transparent !important;
    cursor: ne-resize;
    z-index: 999;
}

.resize .border-bottom-right {
    right: 0;
    bottom: 0;
    height: 4px;
    width: 4px;
    background: transparent !important;
    cursor: se-resize;
    z-index: 999;
}

.cdk-global-overlay-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    // opacity: 0.75;
}

// ends

.export-modal-container {
    max-width: 85vw !important;
    .mat-dialog-container {
        padding: 0px !important;
        overflow: hidden;
    }
}

//for date range selector button color
ngx-daterangepicker-material {
    & {
        .md-drppicker {
            td.active, .md-drppicker td.active:hover {
                background-color: var(--primary) !important;
            }
            
            padding: 20px !important;
            font-family: var(--font-family) !important;
            font-size: 1em !important;
            max-width: 640px;

            .table-condensed {
                border-spacing: 0px;
            }

            th.month {
                text-transform: uppercase;
                color: var(--text);
                font-size: 14px;
            }

            &.double {
                .calendar {
                    max-width: 295px !important;

                    th.available.prev,
                    th.available.next {
                        background: none;
                        position: relative;
                        vertical-align: middle;
                        line-height: 30px;

                        &::before {
                            font-family: "Font Awesome 5 Free";
                            content: "\f104";
                            position: absolute;
                            color: var(--primary);
                            vertical-align: middle;
                            top: 50%;
                            transform: translateY(-50%);
                            height: 100%;
                            font-size: 1.3em;
                            text-align: center;
                        }
                    }

                    &.left {
                        border-right: 1px solid var(--line);

                        th {
                            // &.next {
                            //   display: none;
                            // }
                        }
                    }

                    &.right {
                        th {
                            // &.prev {
                            //   display: none;
                            // }
                        }
                    }

                    td,
                    th {
                        border-radius: 0px !important;
                        min-width: 40px;
                        height: 30px;
                    }

                    td {
                        span {
                            padding: 0px;
                            color: inherit;
                            font-size: 13px;
                            font-weight: 550 !important;
                        }

                        &.available:not(.active) {
                            &:hover {
                                background-color: rgba(var(--primary-rgb), 0.2) !important;
                            }
                        }

                        &::hover {
                            border-radius: 0px !important;
                        }

                        &.in-range {
                            background-color: rgba(var(--primary-rgb), 0.2) !important;
                            border-radius: 0px !important;
                            color: var(--text) !important;
                        }

                        &.active {
                            background-color: var(--primary) !important;
                            border-radius: 0px !important;
                            color: var(--white-color) !important;
                        }
                    }

                    th {
                        span {
                            padding: 0px;
                        }

                        &.available:not(.active):not(.prev):not(.next) {
                            &:hover {
                                background-color: rgba(var(--primary-rgb), 0.2) !important;
                            }
                        }

                        &::hover {
                            border-radius: 0px !important;
                        }
                    }
                }
            }

            //   td.active,
            //   td.active:hover {
            //     background-color: var(--primary) !important;
            //     border-radius: 0px !important;
            //   }
            .start-date,
            .end-date,
            .in-range {
                color: var(--white-color) !important;

                span {
                    opacity: 1 !important;
                }
            }

            .btn {
                color: var(--primary) !important;
                box-shadow: none !important;
                background: transparent !important;
                margin: 0;
                min-width: auto;
                line-height: 25px !important;
            }

            .btn.btn-default::after {
                content: "|";
                color: gray;
                font-size: 19px;
                line-height: 0px;
                margin-left: 13px;
                position: relative;
                top: -1px;
            }

            display: flex;
            flex-flow: row;
            flex-wrap: wrap;

            .ranges {
                order: 3;
                float: none;
                flex: 1 0 100%;
                display: flex;

                ul {
                    margin: 7px 0;

                    li {
                        margin: 0px 5px;
                        display: inline-block;

                        &:hover {
                            background: transparent;
                            border-radius: 16px;
                        }

                        button {
                            padding: 6px 10px;
                            background: #f2f2f2;
                            border-radius: 16px;
                            color: #333;
                            font-size: 12px;

                            &.active {
                                background-color: var(--primary);
                            }
                        }

                        &:first-child {
                            padding-left: 75px;
                            vertical-align: middle;
                            position: relative;

                            &::before {
                                content: "Quick Select";
                                display: block;
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }

            .calendar.left,
            .calendar.right {
                order: 1;
            }

            .buttons {
                order: 4;
                flex: 1 0 100%;
            }

            .btn {
                font-size: 13px;
                line-height: 24px !important;

                &.clear svg {
                    width: 16px;
                    height: 16px;
                }
            }

            &.double {
                .calendar {
                    td {
                        &.active {
                            background-color: var(--primary) !important;
                            border-radius: 0px !important;
                            color: #fff !important;

                            &.off {
                                background-color: #fff !important;
                                border-color: transparent !important;
                                color: #999 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mat-form-field.mat-focused .mat-select-arrow {
    transform: rotate(45deg) !important;
}

.mat-select-search-clear {
    top: 10px;
    background: transparent;
    border: none;
    line-height: 24px;
}

.mat-select-panel {
    position: absolute;
    top: 22px;
    left: 12px;
    font-size: 13px;
    font-weight: 400;
    border-radius: 3px !important;
    border-top-style: none;
}

.mat-select[aria-activedescendant] {
    .mat-select-arrow {
        box-sizing: border-box;
        height: 6px !important;
        width: 6px !important;
        border-left: none !important;
        border-right: 2px solid var(--primary) !important;
        border-top: none !important;
        border-style: solid;
        border-color: var(--primary);
        border-width: 0 2px 2px 0;
        transform: rotate(-135deg) !important;
        -webkit-transform: rotate(-135deg) !important;
    }
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 0px !important;
    border-right-style: none !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0px !important;
    border-left-style: none !important;
}

.mat-select-panel ng-animating {
    visibility: hidden;
}

.mat-select-search-panel {
    .mat-option {
        height: 30px !important;
        line-height: 30px !important;
    }

    //   margin-top: 48px !important;
}

.sort-order-dialog .mat-dialog-container {
    padding: 0 !important;
    overflow: unset !important;
}

.select-dropdown {
    mat-form-field {
        .mat-form-field-wrapper {
            padding-bottom: 0 !important;
        }

        .mat-form-field-infix {
            border-top: none !important;
        }

        .mat-form-field-ripple {
            background-color: var(--primary);
            height: 1px;
        }

        .mat-form-field-underline {
            bottom: 0.25em !important;
        }

        .cross-icon {
            font-size: 14px !important;
            color: rgba(0, 0, 0, 0.54);
        }
    }

    .mat-select-panel {
        font-size: 13px;
        font-weight: 400;
        border-radius: 3px !important;
        border-top-style: none;
    }


    .mat-select-placeholder {
        color: rgba(0, 0, 0, 0.87);
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-start {
        border-radius: 0px !important;
        border-right-style: none !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-end {
        border-radius: 0px !important;
        border-left-style: none !important;
    }

    .mat-select-panel ng-animating {
        visibility: hidden;
    }
}

/* You can add global styles to this file, and also import other style files */
html,
body {
    height: 100%;
    font-family: "Lato", sans-serif;
}

body {
    margin: 0;
}

.content {
    position: relative;
}

.line {
    stroke-width: 1px;
}

/*********************** Common CSS *********************/

.text-light {
    color: rgba(51, 51, 51, 0.6);
}

.text-uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.pulse {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 9px 0px 0px -8px;
    transform: rotateX(55deg);
    z-index: -2;

    &:after {
        content: "";
        border-radius: 50%;
        height: 40px;
        width: 40px;
        position: absolute;
        margin: -13px 0 0 -13px;
        animation: pulsate 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
        box-shadow: 0 0 1px 2px #89849b;
        animation-delay: 1.1s;
    }
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-2000px) rotate(-45deg);
    }

    60% {
        opacity: 1;
        transform: translateY(30px) rotate(-45deg);
    }

    80% {
        transform: translateY(-10px) rotate(-45deg);
    }

    100% {
        transform: translateY(0) rotate(-45deg);
    }
}