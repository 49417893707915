@import "~src/assets/css/styles.scss";

html {
  overflow: hidden;
}

.center {
  text-align: center;
}

.flex {
  display: flex !important;
}

.relative {
  position: relative;
}

.hidden {
  display: none;
}

.scroll-max-400,
.scroll-max-300 {
  overflow-y: auto;
}

.scroll-max-300 {
  max-height: 300px;
}

.scroll-max-400 {
  max-height: 400px;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important; /*click effect color change*/
  background-color: var(--primary) !important;
}
  
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--primary) !important; /*inner circle color change*/
}
  
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary) !important; /*outer ring color change*/
}